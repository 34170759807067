body,
html {
  background-color: #FFFFFF;
  margin: 0;
  font-family: 'Roboto';
  height: 100%;
}

h1,
h2,
p,
ul,
li {
  font-family: 'Roboto';
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

ul.header {
  background-color: #22b686;
  padding: 0;
}

ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #f8f8f8;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.table-spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
}

.background {
  background-color: #f8f8f8;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow-y: scroll;
  z-index: 1;
}

.content {
  background-color: #f8f8f8;
  margin: 0 auto;
  padding-bottom: 3px;
  min-width: 450px;
  max-width: 890px;
  justify-self: center;
  z-index: 2;
}

.NavContainer .NavBar {
  opacity: 1;
  background-color: #f8f8f8;
  max-height: 50px;
  padding-top: 20px;
  float: center;
  display: inline-flex;
  width: 100%;
}

.NavContainer {
  background-color: #f8f8f8;
  z-index: 500;
  opacity: 1;
  text-align: left;
  height: 50px;
  justify-content: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  flex-direction: column;
}

.NavContainer .NavBar .NavLink {
  opacity: 0.7;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.1px;
  color: #45474b;
  margin-left: 35px;
  max-height: 50px;
}

.NavContainer .NavBar .NavLink:hover {
  color: #45474b;

}

.NavContainer .NavBar .NavLink:active {
  color: #12b17b;
}

.NavContainer .active {
  color: #12b17b !important;
}

.dropdown-item.active {
  color: #12b17b;
  background-color: #ffffff;
}

.NavContainer .NavBar .NavLink:focus {
  color: #12b17b;
}

.NavContainer .NavBar .DropDown button {
  width: 64px;
  height: 15px;
  font-family: Roboto;
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.1px;
  color: #45474b;
}

.NavContainer .NavBar .NavBarCollapse {
  background-color: #f8f8f8;
  opacity: 1;
  text-align: right;
}

.navbar-brand {
  margin-top: -6px;
}

.CURB {
  margin-top: -27px;
}

.PAY {
  width: 64px;
  height: 44px;
  font-family: Montserrat;
  font-size: 50px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  padding-top: 10px;
  padding-left: 3px;
  text-align: center;
  color: #45474b;
}

.curbNav {
  margin-left: 6px;
  margin-top: -5px;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.payNav {
  width: 44px;
  height: 30px;
  padding-left: 2px;
  font-family: Montserrat;
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #45474b;
}

.dashboard-cont {
  height: 380px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  float: center;
  justify-self: center;
}


.dashboard-title {
  display: flex;
  float: left;
  padding-left: 20px;
}

.dashboard-cont h3 {
  height: 16px;
  width: 200px;
  font-family: Roboto;
  padding-top: 5px;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #393b3e;
}

.react-datepicker-wrapper input {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  text-indent: 4px;
  height: 40px;
  max-width: 170px;
  width: 170px;
  border-radius: 30px;
  border: none;
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
}

.react-datepicker__navigation {
  top: 0px;
}

.react-datepicker-wrapper {
  margin-left: 55%;
}

.react-datepicker__month-container {
  z-index: 10000;
}

.vertical-cont {
  justify-content: center;
  display: flex;
  flex-direction: column;
  float: center;
}

.horizontal-cont {
  display: flex;
  flex-direction: row;
  float: left;
}

.centered {
  margin: 0 auto;
}

.content button-toolbar {
  float: right;
  clear: initial
}

.content button {
  float: right;
  padding: 3px;
  margin: 5px;
}

.table-component {
  position: sticky;
  position: -webkit-sticky;
  max-width: 97vw;
  top: 0;
  left: 0;
  right: 0;
}

.table-searchbar {
  position: sticky;
  position: -webkit-sticky;
  background-color: #f8f8f8;
  padding-top: 5px;
  top: 3.1em;
  opacity: 1;
  height: 65px;
  z-index: 1000;
  min-width: 620px;
}

.table-striped {
  display: block-inline;
  width: 100%;
  padding: 2px;
  float: center;
  border: none !important;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #ffffff;
  height: 48px;
  width: 30px;
  border: none;
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: #f8f8f8;
  height: 48px;
  width: 30px;
  border: none;
}

.table-striped>thead>tr>td {
  padding-top: 20px;
  border: none;
}

.embedded-table-cont {
  max-height: 300px;
  overflow-y: scroll;
}

.table th {
  border: none;
  border-bottom: none !important;
}

.header {
  opacity: 0.8;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #45474b;
  border-top: none;
}

.table-cont .table reset-expansion-style {
  border-top: solid !important;
  border-top-color: #45474b !important;
  background: #ffffff !important;
}

.expandRow .container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
}

.expandRow h3 {
  height: 14px;
  opacity: 0.8;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #45474b;
}

.expandRow p {
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #393b3e;

}

.div-height-20 {
  height: 20px;
  width: 100vw;
}

.filters {
  position: sticky;
  position: -webkit-sticky;
}

.filters .container {
  background-color: #f8f8f8;
  opacity: 1;
  top: 0;
  margin-top: 4px;
  padding: 13px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 8px;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, .1);
}

.filters h3 {
  opacity: 0.8;
  padding-top: 3px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #45474b;
}

.filter-input {
  height: 40px;
  width: 185px;
  border-radius: 30px;
  border: none;
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  margin-bottom: 3px;

}

.button {
  border-radius: 26px;
  border: solid 1px #12b17b;
  background-color: #12b17b;
  margin: 4px;
  margin-top: 40px;
  padding-left: 3px;
}


.LoadMoreButton {
  width: 120px;
  float: center;
  border-radius: 26px;
  border: solid 1px #12b17b;
  background-color: #12b17b;
  margin-top: 20px;
}

.ExportCSVButton {
  width: 120px;
  float: left;
  border-radius: 26px;
  border: solid 1px #12b17b;
  background-color: #12b17b;
  margin-top: 20px;
}

.page-title h2 {
  padding-top: 15px;
  height: 24px;
  font-family: Roboto;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #45474b;
}


.datepicker-pre {
  height: 40px;
  width: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: none;
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  opacity: 1;
}

.DayPickerInput input {
  height: 40px;
  width: 155px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: none;
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  padding-left: x;
}

.inputStyle {
  flex: 1;
}

.arrow-point {
  margin-left: 10px;
}

.service-img {
  max-width: 25px;
  max-height: 20px;
}

.filter-icon {
  margin-right: 10px;
  margin-top: 5px;
}

.filter {
  margin-top: 15px;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  right: 0;
  height: 40px;
  border-radius: 30px;
  border: none;
  max-width: 97vw;
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  padding-left: 10px;
  padding-top: 6px;
}

.appliedFilter {
  background-color: #12b17b;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  color: #f8f8f8;
  width: fit-content;
  border-radius: 7px;
  margin-right: 3px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 6px;
  height: 28px;
}

.deleteFilter {
  cursor: pointer;
  font-size: 15px;
  border-left: solid 1px #393b3e;
  margin-left: 2px;
  padding-left: 6px;
  padding-right: 5x;
  height: 28px;
  line-height: 28px;
}


.applyFilters {
  padding-top: 20px;
  width: 107px;
  border-radius: 26px;
  border: solid 1px #12b17b;
  background-color: #12b17b;
  float: left;
}

.clearFilters {
  width: 60px;
  border-radius: 26px;
  border: solid 1px #12b17b;
  background-color: #12b17b;
  float: right;
}

.preview {
  height: 100%;
  width: 35%;
  border-style: solid;
  border-width: 1px;
  border-color: #5d5d5d;
  padding-left: 5px;
  margin-left: 5px;
  font-size: 20px;
  max-height: 90vh;
}

.login-BackgroundImage {
  background-image: url("./assets/background.png");
  background-repeat: repeat;
  background-size: cover;
  font-family: 'Roboto';
  overflow: hidden;
  height: 100vh;
}

.container {
  max-width: 100%;
  padding: 0;
  padding-top: 0;
}

.loginBackground {
  padding: 0;
  padding-top: 0;
  opacity: 0.54;
  background-image: linear-gradient(226deg, #2179b1, #12b17b);
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.loginDiv {
  float: right;
  background-color: #FFF;
  height: 100vh;
  max-width: 480px;
  min-width: 400px;
  justify-content: left;
  overflow-y: scroll;
}

.verifyContent {
  padding: 50px;
  padding-top: 60px;
}

.verifyContent h2 {
  padding-top: 20px;
}

.verifyContent p {
  padding-top: 20px;
}

.verifyContent button {
  width: 120px;
}


.loginContent {
  padding: 50px;
  padding-top: 130px;
}

.loginForm {
  font-family: 'Roboto';
  padding-top: 80px;
}

.loginForm .LoginButton {
  width: 186px;
  height: 46px;
  border-radius: 26px;
  border: solid 1px #12b17b;
  background-color: #12b17b;
  margin-top: 40px;
}

.loginForm input {
  width: 310px;
  height: 46px;
  margin-top: 38px;
  border-radius: 2px;
  border: solid 1px #cecece;
  background-color: #ffffff;
}

.loginForm .Log-In {
  width: 70px;
  height: 19px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #45474b;
}

.loginForm .Forgot-Password {
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: #2179b1;
  margin-top: 50px;
}

.signUpContent {
  padding: 50px;
  padding-top: 60px;
}


.signUpForm {
  font-family: 'Roboto';
  padding-top: 40px;
}

.signUpForm .LoginButton {
  width: 186px;
  height: 46px;
  border-radius: 26px;
  border: solid 1px #12b17b;
  background-color: #12b17b;
  margin-top: 20px;
}

.signUpForm label {
  margin-top: 15px;
  margin-bottom: 5px;
}

.signUpForm input {
  width: 310px;
  height: 46px;
  margin-top: 1px;
  border-radius: 2px;
  border: solid 1px #cecece;
  background-color: #ffffff;
}

.signUpForm .Create-Account {
  width: 150px;
  height: 19px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #45474b;
}

.userProfile {
  margin-top: 20px;
}

.hamburger-menu {
  width: 30px;
  height: 30px;
}

.hamburger-icon {
  cursor: pointer;
  /* position: absolute;
  top: 10px;
  left: 10px; */
  min-width: 30px;
  min-height: 30px;
  padding: 3px;
  margin-left: -3px;
  padding-right: 2px;
}

.menu {
  position: absolute;
  top: 60px;
  left: 17px;
  min-width: 200px;
  max-width: 200px;
  height: 150px;
  background: #ffffff;
  box-shadow: 0 0 50px 20px rgba(0, 0, 0, .1);
  padding-top: 20px;
  padding-left: 10px;
  border-radius: 8px;
}

.menu a {
  height: 24px;
  opacity: 0.8;
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #45474b;
}

.menu .row {
  padding-top: 10px;
}

footer {
  margin-top: 15px;
  margin-right: 10px;
}

.EditUser {
  padding: 20px;
}

.userProfile .form-control {
  margin-bottom: 5px;
}

.userProfile p {
  padding-top: 8px;
}

.userProfile button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.inviteDiv {
  position: absolute;
  position: -webkit-sticky;
  background-color: #f8f8f8;
  width: 100%;
  opacity: 1;
  top: 0;
  margin-top: 4px;
  padding-left: 4px;
  
  border-radius: 30px;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, .1); 
}

.inviteEmailInput {
  margin-left: 2px;
  min-width: 85%;
  border-radius: 30px;
  border: none;
  height: 35px;
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.04);
}

.inviteButton {
  float: right;
  width: 120px;
  border-radius: 26px;
  height: 37px;
  border: solid 1px #12b17b;
  background-color: #12b17b;
}

.alert{
  margin-top: 5px;
}

.alert .p {
  padding: 7px; 
}